import React from 'react';
import { Link } from 'react-router-dom';
import { MenuItem } from 'src/aiser/components/menu';
import getBootstrapData from 'src/utils/getBootstrapData';
import { isFrontendRoute } from 'src/views/routes';

const UNPAID_EXCLUDE = ['Chat To Chart'];

const bootstrapData = getBootstrapData();
// menuData.current_subscription

export function getNavbarMenuItems(props: IAiserMenuItem[]): MenuItem[] {
  const menu: Array<MenuItem> = [];

  const subscription = bootstrapData.common.ui_menu?.current_subscription;
  const env_tag = bootstrapData.common.menu_data.environment_tag;

  props.forEach((item: IAiserMenuItem) => {
    if (UNPAID_EXCLUDE.includes(item.name)) {
      if (!subscription && env_tag.text !== 'Development') {
        return;
      }
    }

    const data: MenuItem = {
      key: item.name!,
      label: isFrontendRoute(item.url) ? (
        <Link to={item.url || ''}>{item.label}</Link>
      ) : item.url ? (
        <a href={item.url || ''}>{item.label}</a>
      ) : (
        item.label
      ),
      icon: <i className={`fa ${item.icon}`} />,
      children: item.childs?.length
        ? getNavbarMenuItems(item.childs?.map((child: IAiserMenuItem) => child))
        : undefined,
    };

    menu.push(data);
  });

  return menu;
}
